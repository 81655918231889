import { useCallback, useContext, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { GET_CUSTOMER_MESSAGES, GET_MESSAGE } from "utils/queries";
import UserContext from "contexts/UserContext";

const useCustomerMessages = () => {
  const MESSAGES_PER_PAGE = 10;

  const { eventId } = useContext(UserContext);
  const { captureError } = useRollbar();
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const { query } = useApi();
  const [page, setPage] = useState(1);
  const [messagesTotal, setMessagesTotal] = useState(0);
  const [channel, setChannel] = useState("all");

  const fetchCustomerMessages = useCallback(async (customerId) => {
    try {
      if (!customerId) {
        return;
      }

      if (!eventId) {
        return;
      }
      setLoading(true);
      const {
        data: { customer },
      } = await query(
        GET_CUSTOMER_MESSAGES({
          eventId: eventId,
          id: customerId,
          limit: MESSAGES_PER_PAGE,
          page: page,
          order: "desc",
          channel: channel
        })
      );
      setMessagesTotal(customer.messages?.totalCount);
      setMessages([...messages, ...customer.messages?.collection]);
    } catch (e) {
      captureError(e);
      console.log("Failed to load messages", e);
    }
    setLoading(false);
  }, [eventId, MESSAGES_PER_PAGE, page, channel, captureError, query]);

  const fetchMessage = useCallback(async (id) => {
    if (!eventId) {
      return;
    }

    try {
      setLoading(true);
      const {
        data: { message },
      } = await query(
        GET_MESSAGE({ eventId, id })
      );
      
      if (message) {
        message.highlight = true;
        setMessages((currentMessages) => [...currentMessages, message]);
      } 
    } catch (e) {
      captureError(e);
      console.log('failed to load message', e);
    }
    setLoading(false);
  }, [eventId, query]);

  return {
    MESSAGES_PER_PAGE,
    loading,
    setLoading,
    messages,
    setMessages,
    page,
    setPage,
    messagesTotal,
    setMessagesTotal,
    channel,
    setChannel,
    fetchCustomerMessages,
    fetchMessage
  };
};

export default useCustomerMessages;
