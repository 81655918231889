import React, { useCallback, useEffect } from "react";
import MainLayout from "components/MainLayout";
import DateTimeSelector from "components/DateTimeSelector";
import TimezoneCombo from "components/TimezoneCombo";
import useEvents from "hooks/useEvents";
import { useSnackbar } from "notistack";
import moment from "moment-timezone";

const EditEvent = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar();

  const eventHooks = useEvents();
  const { loading, event, setEvent, startDateTime, setStartDateTime, endDateTime, setEndDateTime, fetchEventSettings, onUpdate } = eventHooks;

  useEffect(() => {
    fetchEventSettings(id);
  }, [id, fetchEventSettings]);
  
  const handleChange = (key, value) => {
    setEvent((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const onSaveEventSettings = useCallback(() => {
    if (endDateTime && moment.utc(endDateTime) <= moment.utc(startDateTime)) {
      enqueueSnackbar("End date must be after the start date", {
        autoHideDuration: 3000,
        variant: "error",
      });
      return;
    }    

    onUpdate();
  }, [startDateTime, endDateTime, onUpdate]);

  return (
    <MainLayout>
      <div className="w-full max-w-500">
        <form>
          <h1 id="primary-heading" className="font-bold text-3xl">Edit Event</h1>

          { event ? (
            <div className="mt-6 font-medium text-lg">

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <div className="mt-2">
                    <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        type="text"
                        name="name"
                        id="name"
                        required={true}
                        value={event?.name || ""}
                        onChange={(e) => handleChange('name', e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="locationName" className="block text-sm font-medium leading-6 text-gray-900">
                    Location
                  </label>
                  <div className="mt-2">
                    <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        type="text"
                        name="locationName"
                        id="locationName"
                        required={true}
                        value={event?.locationName || ""}
                        onChange={(e) => handleChange('locationName', e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="locationUrl" className="block text-sm font-medium leading-6 text-gray-900">
                    Location link (URL)
                  </label>
                  <div className="mt-2">
                    <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                      <input
                        className="block flex-1 rounded-md border border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 bg-white py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        type="text"
                        name="locationUrl"
                        id="locationUrl"
                        required={true}
                        value={event?.locationUrl || ""}
                        onChange={(e) => handleChange('locationUrl', e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">Start Date/Time:</label>
                  <div className="flex justify-start">
                    <DateTimeSelector
                      dateTime={startDateTime}
                      setDateTime={setStartDateTime}
                      timezone={event.timezone}
                      allowPreviousDates={!!startDateTime}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900 pb-1.5">End Date/Time:</label>
                  <div className="flex justify-start">
                    <DateTimeSelector
                      dateTime={endDateTime}
                      setDateTime={setEndDateTime}
                      timezone={event.timezone}
                      allowPreviousDates={!!endDateTime}
                    />
                  </div>
                </div>

                <div className="sm:col-span-6">
                  <TimezoneCombo timezone={event?.timezone} setTimezone={(value) => handleChange('timezone', value)} />
                </div>
              </div>

              <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                  onClick={onSaveEventSettings}
                  type="button"
                  disabled={loading}
                  className="mr-10 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {`${loading ? "Saving..." : "Save"}`}
                </button>
              </div>
            </div>
          ) : <p className="mt-4">Loading...</p>} 
        </form>
      </div>
    </MainLayout>
  )
};

export default EditEvent;
