import React, { useEffect, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import SetupPhoneEventDialog from "./SetupPhoneEventDialog";
import useApi from "hooks/useApi"
import useRollbar from "hooks/useRollbar"
import { enqueueSnackbar } from "notistack"
import { GET_AVAILABLE_PHONE_NUMBERS } from "utils/queries";
import SuccessFailureAlert from "./SuccessFailureAlert";
import { PURCHASE_NUMBER_FOR_EVENT } from "utils/mutations";
import useDebounce from "hooks/useDebounce";

const NoPhoneEventAlert = ({ eventId }) => {
  const { query, mutate } = useApi()
  const { captureError } = useRollbar()

  const [dialog, setDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [selectedNumber, setSelectedNumber] = useState("")
  const [filterBy, setFilterBy] = useState("areaCode")
  const [filterByValue, setFilterByValue] = useState("")
  const debounceSearch = useDebounce(filterByValue)

  const [alert, setAlert] = useState(false)
  const [alertType, setAlertType] = useState("")

  useEffect(() => {
    if (dialog) fetchAvailableNumbers()
  }, [dialog])

  useEffect(() => {
    if (!dialog) return;

    const params = {
      areaCode: filterBy === "areaCode" ? debounceSearch : null,
      containsNumbers: filterBy === "containsNumbers" ? debounceSearch : null,
      locality: filterBy === "locality" ? debounceSearch : null
    };

    fetchAvailableNumbers(...Object.values(params))
  }, [debounceSearch]);  

  const fetchAvailableNumbers = async (areaCode = null, containsNumbers = null, locality = null) => {
    setIsLoading(true);

    try {
      const queryString = GET_AVAILABLE_PHONE_NUMBERS(eventId, areaCode, containsNumbers, locality)
      const res = await query(queryString)
      const data = res?.data?.availablePhoneNumbers ?? []

      setAvailableNumbers(data)
      setSelectedNumber(data?.[0] ?? "")
    } catch (e) {
      captureError(e)
    }

    setIsLoading(false);
  }

  const handleFilterChange = (value) => {
    setFilterBy(value)
    setFilterByValue("")
  }

  const handleSearchChange = (value) => {
    let newValue = ["areaCode", "containsNumbers"].includes(filterBy) ? value.replace(/\s/g, "") : value;
  
    if ((filterBy === "areaCode" && newValue.length > 3) || (filterBy === "containsNumbers" && newValue.length > 4)) return;
  
    setFilterByValue(newValue);
  };

  const handleDialogClose = () => {
    setDialog(false);
    setSelectedNumber("");

    setFilterBy("areaCode");
    setFilterByValue("");
  }

  const handleAlertClose = () => {
    setAlert(false);

    if (alertType === "success") window.location.reload();
  }

  const onSave = async () => {
    if (!selectedNumber) return

    setFormLoading(true)

    try {
      const res = await mutate(PURCHASE_NUMBER_FOR_EVENT(eventId, selectedNumber))

      setAlertType(res.data.purchasePhoneNumberForEvent.success ? "success" : "error");
      setDialog(false);
      setAlert(true);
    } catch (e) {
      captureError(e)
      enqueueSnackbar("Failed to purchase number", {
        autoHideDuration: 3000,
        variant: "error"
      })
    }

    setFormLoading(false)
  }

  return (
    <>
      <div className="rounded-md bg-yellow-50 p-4 flex items-center gap-x-3">
        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        <p className="text-sm text-yellow-700">This event doesn't have a phone number.</p>
        <button
          type="button"
          className="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={() => setDialog(true)}
        >
          SET UP
        </button>
      </div>
      {dialog &&
        <SetupPhoneEventDialog 
          open={dialog} 
          closeDialog={handleDialogClose} 
          isLoading={isLoading} 
          formLoading={formLoading}
          availableNumbers={availableNumbers} 
          selectedNumber={selectedNumber} 
          setSelectedNumber={setSelectedNumber}
          filterBy={filterBy}
          handleFilterChange={handleFilterChange}
          filterByValue={filterByValue}
          handleSearchChange={handleSearchChange}
          handleSubmit={onSave}
        />
      }
      {alert &&
        <SuccessFailureAlert
          open={alert}
          closeAlert={handleAlertClose}
          phone={selectedNumber}
          type={alertType}
        />
      }
    </>
  );
};

export default NoPhoneEventAlert;
