import React, { useEffect, useMemo, useState, forwardRef } from 'react'
import { CalendarIcon, ChevronDownIcon, ClockIcon } from '@heroicons/react/24/outline'
import { defaultNearest15minutesDate, getDefaultScheduleTime, parseDateTime } from 'utils/formatter';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

const DateTimeSelectorNew = ({ dateTime, setDateTime, timezone, allowPreviousDates = false }) => {
  const todayDate = new Date(moment.tz(timezone).format('MM/DD/YYYY'));

  const [scheduleDate, setScheduleDate] = useState(dateTime ? parseDateTime(dateTime, timezone) : todayDate)
  const [scheduleTime, setScheduleTime] = useState(dateTime ? parseDateTime(dateTime, timezone) : getDefaultScheduleTime(timezone))

  useEffect(() => {
    if (scheduleDate && scheduleTime) setDateTime(getDateTimeInDiffTimezone())
  }, [scheduleDate, scheduleTime])

  const getDateTimeInDiffTimezone = () => {
    if (!scheduleDate || !scheduleTime) return null;
  
    return moment.tz(
      `${moment(scheduleDate).format('YYYY-MM-DD')} ${moment(scheduleTime).format('HH:mm:ss')}`,
      'YYYY-MM-DD HH:mm:ss',
      timezone
    ).utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  };

  const handleDateChange = (selectedDate) => {
    const isToday = selectedDate?.toDateString() === todayDate.toDateString();
    const nearestTime = isToday 
      ? getDefaultScheduleTime(timezone)
      : moment(todayDate)
          .add(moment(selectedDate).diff(todayDate, 'days'), 'days')
          .set({
            hour: moment(scheduleTime).hour(),
            minute: moment(scheduleTime).minute(),
            second: moment(scheduleTime).second(),
          }).toDate();
  
    setScheduleDate(selectedDate);
    setScheduleTime(nearestTime);
  };

  const handleTimeChange = (selectedTime) => {
    if (allowPreviousDates) {
      setScheduleTime(selectedTime);
      return;
    } 

    const datetime = new Date(
      scheduleDate.getFullYear(),
      scheduleDate.getMonth(),
      scheduleDate.getDate(),
      selectedTime.getHours(),
      selectedTime.getMinutes(),
      selectedTime.getSeconds(),
      selectedTime.getMilliseconds()
    );
  
    if (datetime >= minTime) setScheduleTime(datetime);
  };  

  const minTime = useMemo(() => {
    if (scheduleDate?.toDateString() !== todayDate.toDateString()) 
      return moment().startOf("day").toDate();
  
    return new Date(defaultNearest15minutesDate().format('MM/DD/YYYY')).toDateString() > todayDate.toDateString() ? scheduleTime : getDefaultScheduleTime(timezone);
  }, [scheduleDate, scheduleTime]);  

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => {
    const today = moment.tz(timezone).startOf('day');
    const tomorrow = moment.tz(timezone).add(1, 'days').startOf('day');
    const scheduleMoment = moment.tz(value, 'MM/DD/YYYY', timezone); 
  
    const displayValue = scheduleMoment.isSame(today, 'day') ? 'Today' : scheduleMoment.isSame(tomorrow, 'day') ? 'Tomorrow' : value;

    return (
      <div className="relative w-48 mr-4" onClick={onClick}>
        <CalendarIcon className='h-5 w-5 absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer' />
        <input
          type="text"
          className="w-full pl-9 pr-8 pt-2 border rounded-md focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 border-gray-300"
          value={displayValue}
          onClick={onClick}
          readOnly
          ref={ref}
        />
        <ChevronDownIcon className='h-4 w-4 absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer' />
      </div>
    );
  });

  return (
    <>
      <DatePicker
        selected={scheduleDate}
        minDate={allowPreviousDates ? undefined : todayDate}
        onChange={handleDateChange}
        dateFormat='MM/dd/yyyy'
        dayClassName={(date) => date.toDateString() === scheduleDate.toDateString() ? 'bg-indigo-600 hover:bg-indigo-700 text-white' : ''}
        customInput={<CustomDateInput />}
      />
      <DatePicker
        showIcon
        showTimeSelect
        selected={scheduleTime}
        timeIntervals={15}
        showTimeSelectOnly
        toggleCalendarOnIconClick
        dateFormat='h:mm aa'
        minTime={allowPreviousDates ? new Date(0, 0, 0, 0, 0, 0) : minTime}
        maxTime={moment().endOf("day").toDate()}
        onChange={handleTimeChange}
        className="rounded-md w-32 !pl-9 !py-2 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 border-gray-300"
        icon={<ClockIcon className="h-5 w-5 absolute top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer" />}
      />
    </>
  );
};

export default React.memo(DateTimeSelectorNew);