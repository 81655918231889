import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import UserContext from "contexts/UserContext";
import { SEND_MESSAGE } from "utils/mutations";
import useRollbar from "hooks/useRollbar";
import useSmsBroadcast from "hooks/useSmsBroadcast";
import { useSnackbar } from "notistack";
import useApi from "hooks/useApi";
import AddLink from "components/AddLinkButton.js";
import NoPhoneEventTooltip from "components/eventPhone/NoPhoneEventTooltip";
import { Listbox, Transition } from '@headlessui/react'
import { PaperAirplaneIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import ScheduleDialog from "./ScheduleDialog";

const SendMessage = ({ customerId, unsubscribed, editPerson, customerPhone, msgLoading, onSendComplete, page = 1 }) => {
  const { captureError } = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const { apolloClient } = useApi();

  const { eventId, hasEventPhone, ecommerceExperienceFeatureFlag, eventTimezone } = useContext(UserContext);

  const { message, setMessage, link, setLink, addLink, handleModalOpen, handleModalClose, utmCampaign, setUtmCampaign, open, linkError } = useSmsBroadcast();

  const [loading, setLoading] = useState(false);

  const [messageSubject, setMessageSubject] = useState(null);
  const [showGoToBottom, setShowGoToBottom] = useState(false);
  
  const [scheduleDialog, setScheduleDialog] = useState(false);

  const handleScroll = () => {
    const { innerHeight } = window;
    const { scrollHeight, scrollTop } = document.documentElement;
  
    // Adding a small buffer to handle cases where rounding issues might cause the check to fail
    const buffer = 5; 
    const isAtBottom = scrollTop + innerHeight >= scrollHeight - buffer;
  
    setShowGoToBottom(!isAtBottom);
  };  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll();

    if (page <= 1) {
      scrollToBottom();
    }
  }, [msgLoading]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  };

  const channel = ecommerceExperienceFeatureFlag ? "email" : "sms";

  const canSendMessage = useMemo(() => {
    if (!ecommerceExperienceFeatureFlag && !hasEventPhone) return;
    if (unsubscribed) return;
    if (!customerPhone) return;

    return message && message !== "";
  }, [unsubscribed, ecommerceExperienceFeatureFlag, hasEventPhone, message, customerPhone]);

  const sendMessage = useCallback(async () => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          eventId: parseInt(eventId),
          customerId: customerId,
          subject: messageSubject,
          body: message,
          channel: channel
        },
      });
      // enqueueSnackbar("Message was sent!", {
      //   autoHideDuration: 3000,
      //   variant: "success",
      // });
      setLoading(false);
      setMessageSubject("");
      setMessage("");
      onSendComplete();
    } catch (e) {
      enqueueSnackbar("Failed to send message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  }, [eventId, messageSubject, message, setMessage, channel, customerId, onSendComplete, apolloClient, enqueueSnackbar, captureError]);

  const onMessageChange = useCallback((e) => {
    setMessage(e.target.value)
  }, [setMessage]);

  const onSubjectChange = useCallback((e) => {
    setMessageSubject(e.target.value)
  }, [setMessageSubject]);

  const goToEndScroll = () => {
    if (message === "") {
      scrollToBottom();
      // const container = document.getElementById('root');
      // container.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
    }
  };

  return (
    <div id="send-message" className="flex items-start py-5 -mb-10 bg-[#fafafa] sticky bottom-[0px]">
      {/* <div className="flex-shrink-0">
        <div className="uppercase font-bold flex items-center justify-center h-8 w-8 rounded-full border border-indigo-900 bg-indigo-900 text-white">
          {userInitials(user?.firstName, user?.lastName)}
        </div>
      </div> */}
      {showGoToBottom && <div className="absolute -top-5 w-full flex justify-center">
        <button className="rounded-full border p-2 bg-white border-indigo-600" onClick={scrollToBottom}><ChevronDownIcon className="w-4 h-4 text-indigo-600"/></button>
      </div>}
      <div className="min-w-0 flex-1">
        <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
          <label htmlFor="comment" className="sr-only">
            Write a message
          </label>
          { channel === "email" &&
            <input
            type="text"
            name="subject"
            id="subject"
            className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
            placeholder="Subject"
            onChange={onSubjectChange}
            value={messageSubject}
            />
          }
          <textarea
            rows={3}
            name="comment"
            id="comment"
            autoFocus={false}
            className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
            placeholder="Write a message..."
            onFocus={goToEndScroll}
            onChange={onMessageChange}
            value={message}
          />

          <div className="bg-white py-2 border-t border-gray-300">
            <div className="py-px">
              <div className="h-9 flex justify-between mr-2">
                <div>
                  <AddLink
                    type="icon"
                    link={link}
                    setLinkState={setLink}
                    addLinkClickHandler={addLink}
                    handleModalOpen={handleModalOpen}
                    handleModalClose={handleModalClose}
                    modalStatus={open}
                    utmCampaign={utmCampaign}
                    setUtmCampaign={setUtmCampaign}
                    isError={linkError}
                  />
                </div>
                { ecommerceExperienceFeatureFlag ?
                  <>
                    <button
                      id="send-message-button"
                      onClick={sendMessage}
                      type="button"
                      className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
                      disabled={loading || !canSendMessage}
                    >
                      <PaperAirplaneIcon className="h-4 w-4 mr-2"/>
                      <span>Send</span>
                    </button>
                  </>
                : (
                  <>
                    <Listbox disabled={message == '' || !hasEventPhone || unsubscribed || !customerPhone || loading}>
                      {({ open }) => (
                        <>
                          <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                          <div className="">
                            <div className={`inline-flex divide-x divide-indigo-700 rounded-md shadow-sm ${message == '' ? 'opacity-25' : ''}`}>
                              <button
                                id="send-message-button"
                                onClick={sendMessage}
                                type="button"
                                className="inline-flex items-center gap-x-1.5 rounded-l-md bg-indigo-600 px-3 py-2 text-white shadow-sm"
                                disabled={message == '' || !hasEventPhone || unsubscribed || !customerPhone || loading}
                              >
                                <PaperAirplaneIcon className="h-4 w-4 mr-2"/>
                                <span>Send</span>
                              </button>
                              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-600 p-2 hover:bg-indigo-700 focus:outline-none">
                                <span className="sr-only">Change published status</span>
                                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                              </Listbox.Button>
                            </div>
                            <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                              <Listbox.Options className="absolute right-2 z-10 mt-2 w-60 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none top-16 sm:top-14">
                                <Listbox.Option className='hover:bg-indigo-600 hover:text-white p-3 text-sm cursor-pointer' onClick={() => setScheduleDialog(true)}>
                                  Schedule for later
                                </Listbox.Option>
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </>
                )}

                { (!hasEventPhone || unsubscribed || !customerPhone) && 
                  <NoPhoneEventTooltip elementId="#send-message-button"
                    eventPhone={hasEventPhone}
                    subscribed={!unsubscribed}
                    customerPhone={customerPhone}
                    editPerson={editPerson}
                  />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      { scheduleDialog && 
        <ScheduleDialog 
          open={scheduleDialog} 
          setOpen={setScheduleDialog} 
          eventId={eventId} 
          customerId={customerId} 
          message={message} 
          setMessage={setMessage} 
          eventTimezone={eventTimezone}
        /> 
      }
    </div>
  );
};

export default SendMessage;
