import numeral from 'numeral'
import moment from 'moment-timezone';

export const userInitials = (firstName, lastName) => `${firstName?.charAt(0)}${lastName?.charAt(0)}`

export const currency = v => numeral(v).format('$0,0.00')

export const countFormat = v => numeral(v).format('0,0')

export const underlineToCapital = s => s.split('_').map(n => n[0].toUpperCase() + n.slice(1)).join(' ')

export const getAbbreviation = (text, limit) => {
  if (text && text.length > limit) {
    return text.slice(0, limit) + "..."
  } 
  
  return text
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';

  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const getTimeZoneInfo = (timezone) => {
  if (!timezone) return ''

  const options = {
    timeZone: timezone,
    timeZoneName: 'long',
  };

  const formatter = new Intl.DateTimeFormat('en-US', options);
  const date = new Date();

  // Get the timezone name
  const timeZoneName = formatter.formatToParts(date).find(part => part.type === 'timeZoneName').value;

  // Calculate the offset for the specific timezone
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone })); // Ensure timezone is properly passed
  const offsetInMinutes = (utcDate - tzDate) / 60000; // Difference in milliseconds to minutes

  const hours = Math.floor(Math.abs(offsetInMinutes) / 60).toString().padStart(2, '0');
  const minutes = (Math.abs(offsetInMinutes) % 60).toString().padStart(2, '0');
  const sign = offsetInMinutes <= 0 ? '+' : '-';
  const formattedOffset = `GMT ${sign}${hours}:${minutes}`;

  // Combine timezone name and offset
  return `${timeZoneName} (${formattedOffset})`;
};

export const formatDateWithTimezone = (date, timezone) => {
  if (!date || !timezone) return "";
  
  const momentDate = moment.utc(date).tz(timezone)
  if (!momentDate._isValid) return ''

  return `${momentDate.format("ddd, M/D LT z")}`
}

export const formatDate = (date, timezone) => {
  if (!date || !timezone) return "";

  const momentDate = moment.utc(date).tz(timezone);
  if (!momentDate.isValid()) return "";

  return `${momentDate.format("ddd MMM D, YYYY")}`;
};

export const formatStartEndDate = (start, end, timezone) => {
  if (!timezone) return ''

  const startMoment = moment.utc(start).tz(timezone);
  const endMoment = moment.utc(end).tz(timezone);
  
  if (!startMoment.isValid() || !endMoment.isValid()) return '';

  return `${startMoment.format("MMMM D, YYYY LT")} - ${endMoment.format("LT")}`;
}

export const formatTime = (date, timezone) => {
  if (!date || !timezone) return "";

  const momentDate = moment.utc(date).tz(timezone);
  if (!momentDate.isValid()) return "";

  return `${momentDate.format("LT")}`
}

export const formatFullDateWithTimezone = (date, timezone) => {
  if (!date || !timezone) return "";

  const momentDate = moment.utc(date).tz(timezone);
  if (!momentDate._isValid) return ''

  return `${momentDate.format("ddd, M/D/YY LT")}`
}

export function defaultNearest30minutesDate(timezone = 'America/Los_Angeles') {
  if (!timezone) return "";

  const start = moment().tz(timezone);
  let remainder = 30 - (start.minute() % 30);

  // If the remainder is less than 5 minutes we add 30 minutes more
  if (remainder <= 5) {
    remainder = remainder + 30;
  }

  return moment(start).add(remainder, "minutes").format("YYYY-MM-DDTHH:mm");
};

export function defaultNearest15minutesDate(timezone = 'America/Los_Angeles') {
  if (!timezone) return "";

  const start = moment().tz(timezone);
  let remainder = 15 - (start.minute() % 15);

  // If the remainder is less than 2 minutes we add 15 minutes more
  if (remainder <= 2) {
    remainder = remainder + 15;
  }

  return start.add(remainder, "minutes").set("second", 0);
};

export function getDefaultEndTime(timezone) {
  if (!timezone) return "";

  const momentObj = moment().tz(timezone);
  let remainder = 15 - (momentObj.minute() % 15);
  if (remainder <= 2) remainder += 15;

  momentObj.add(remainder + 30, "minutes").set("second", 0);

  return momentObj.utc().format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
}

export function getDefaultScheduleTime(timezone) {
  if (!timezone) return "";

  const momentObj = moment().tz(timezone);
  let remainder = 15 - (momentObj.minute() % 15);
  if (remainder <= 2) remainder += 15;

  momentObj.add(remainder, "minutes").set("second", 0);

  return new Date(momentObj.format("YYYY-MM-DDTHH:mm:ss"));
};

export function parseDateTime(datetime, timezone) {
  if (!timezone) return "";

  const momentObj = moment(datetime).tz(timezone);
  return new Date(momentObj.format("YYYY-MM-DDTHH:mm:ss"));
}

export const formatDateDisplay = date => {
  const momentDate = moment(date)
  if (!momentDate._isValid) return ''
  return momentDate.format('M/D/YY')
}

export const formatDateQuery = date => {
  const momentDate = moment(date)
  if (!momentDate._isValid) return ''
  return momentDate.format('YYYY-MM-DD')
}

export const sortQuery = (id, desc) => (id ? `
    sortOrder: {order: ${desc ? 'DESC' : 'ASC'}, fieldName: "${id}"}
  ` : '')

export const getSearchConditions = state => {
  const {
    fullname: {
      id: fullname
    },
    lifetimeSpend: {
      from: lsfrom,
      to: lsto
    },
    lastPurchase: {
      from: lpfrom,
      to: lpto
    },
    purchaseCount: {
      from: pcfrom,
      to: pcto
    },
    address: {
      address1,
      address2,
      city,
      state: addressState,
      zip
    },
    purchaseHistory: {
      category,
      brand,
      description,
      from: phfrom,
      to: phto
    },
    assignedAssociate: {
      id: aaid
    },
    customerProperties: {
      options: cpOptions,
      chips: cpChips,
      changed: ignore,
      ...selected
    },
    activeList: {
      id: alid,
      dismissed: aldismissed
    },
    emailHistory: {
      efrom: ehfrom,
      eto: ehto,
      subject: ehsubject,
      body: ehbody,
      from: ehdfrom,
      to: ehdto,
      opened: ehopened
    },
    eventHistory: {
      id: evid,
      from: evfrom,
      to: evto
    },
    lastContacted: {
      option: lcop,
      from: lcfrom,
      to: lcto
    },
    contactInformation: {
      email: emid,
      phone: phid
    },
    subscriptions: {
      email: sem,
      phone: sph
    }
  } = state

  const emailCondition = emid ? `{fieldName: "email", conditionOperator: CONTAINS, conditionValue: "${emid}"},` : ''

  const phoneCondition = phid ? `{fieldName: "phone", conditionOperator: CONTAINS, conditionValue: "${phid}"},` : ''

  const fullnameCondition = fullname ? `{fieldName: "fullname", conditionOperator: CONTAINS, conditionValue: "${fullname}"},` : ''

  const lifetimeSpendCondition = lsfrom || lsto ? `{
        fieldName: "lifetime_spend"
        conditionOperator: RANGE
        ${lsto ? `conditionToValue: "${lsto}"` : ''}
        ${lsfrom ? `conditionFromValue: "${lsfrom}"` : ''}
      }` : ''

  const lastPurchaseCondition = lpfrom || lpto ? `{
        fieldName: "last_order_created_at"
        conditionOperator: RANGE
        ${lpto ? `conditionToValue: "${lpto}"` : ''}
        ${lpfrom ? `conditionFromValue: "${lpfrom}"` : ''}
      }` : ''

  const purchaseCountCondition = pcfrom || pcto ? `{
        fieldName: "orders_count"
        conditionOperator: ${pcfrom === pcto ? 'EQUALS' : 'RANGE'}
        ${pcto !== pcfrom && pcto ? `conditionToValue: "${pcto}"` : ''}
        ${pcto !== pcfrom && pcfrom ? `conditionFromValue: "${pcfrom}"` : ''}
        ${pcto === pcfrom ? `conditionValue: "${pcto}"` : ''}
      }` : ''

  const address1Contition = address1 ? `{
        fieldName: "address1"
        subqueryType: Address
        conditionOperator: CONTAINS
        conditionValue: "${address1}"
      }` : ''

  const address2Contition = address2 ? `{
        fieldName: "address2"
        subqueryType: Address
        conditionOperator: CONTAINS
        conditionValue: "${address2}"
      }` : ''

  const cityContition = city ? `{
        fieldName: "city"
        subqueryType: Address
        conditionOperator: CONTAINS
        conditionValue: "${city}"
      }` : ''

  const stateContition = addressState ? `{
        fieldName: "state"
        subqueryType: Address
        conditionOperator: CONTAINS
        conditionValue: "${addressState}"
      }` : ''

  const zipContition = zip ? `{
        fieldName: "zip"
        subqueryType: Address
        conditionOperator: CONTAINS
        conditionValue: "${zip}"
      }` : ''

  const categoryContition = category ? `{
        fieldName: "product_type"
        subqueryType: LineItem
        conditionOperator: CONTAINS
        conditionValue: "${category}"
      }` : ''

  const brandContition = brand ? `{
        fieldName: "vendor"
        subqueryType: LineItem
        conditionOperator: CONTAINS
        conditionValue: "${brand}"
      }` : ''

  const descriptionContition = description ? `{
        fieldName: "title"
        subqueryType: LineItem
        conditionOperator: CONTAINS
        conditionValue: "${description}"
      }` : ''

  const purchaseHistoryRangeCondition = phfrom || phto ? `{
        fieldName: "price"
        subqueryType: LineItem
        conditionOperator: RANGE
        ${phfrom ? `conditionFromValue: "${phfrom}"` : ''}
        ${phto ? `conditionToValue: "${phto}"` : ''}
      }` : ''

  const unassigned = `{
        fieldName: "associate_id"
        conditionOperator: IS_NULL
      }`

  const assignedAssociateCondition = aaid ? (
    aaid === 'unassigned' ? unassigned : `{
        fieldName: "associate_id"
        conditionOperator: EQUALS
        conditionValue: "${aaid}"
      }`
  ) : ''

  const customerProperties = Object.keys(selected).map(s => {
    if (!cpOptions && selected) return null
    const value = selected[s]
    const { prefix, name } = cpOptions[s]
    return `{fieldName: "${prefix}${name}", conditionOperator: CONTAINS, conditionValue: "${value}" }`
  }).join('')

  const activeListCondition = alid ? `
      {
        fieldName: "list_id"
        conditionOperator: EQUALS
        subqueryType: ListMember
        conditionValue: "${alid}"
      }
      ${aldismissed === 'true' ? '' : `{
        fieldName: "dismissed"
        conditionOperator: EQUALS
        conditionValue: "false"
        subqueryType: ListMember
      }`}
  ` : ''

  const ehFromCondition = ehfrom ? `{
        fieldName: "from"
        conditionOperator: CONTAINS
        subqueryType: Email
        conditionValue: "${ehfrom}"
      }` : ''

  const ehToCondition = ehto ? `{
        fieldName: "to"
        conditionOperator: CONTAINS
        subqueryType: Email
        conditionValue: "${ehto}"
      }` : ''

  const ehSubjectCondition = ehsubject ? `{
        fieldName: "subject"
        conditionOperator: CONTAINS
        subqueryType: Email
        conditionValue: "${ehsubject}"
      }` : ''

  const ehBodyCondition = ehbody ? `{
        fieldName: "body"
        conditionOperator: CONTAINS
        subqueryType: Email
        conditionValue: "${ehbody}"
      }` : ''

  const ehDateRangeCondition = ehdto || ehdfrom ? `{
        fieldName: "sent_at"
        conditionOperator: RANGE
        subqueryType: Email
        ${ehdfrom ? `conditionFromValue: "${ehdfrom}"` : ''}
        ${ehdto ? `conditionToValue: "${ehdto}"` : ''}
      }` : ''

  const ehOpenedCondition = ehopened ? `{
        fieldName: "opened"
        conditionOperator: EQUALS
        subqueryType: Email
        conditionValue: "${ehopened}"
      }` : ''

  const eventHistoryCondition = evid && (evfrom || evto) ? `{
        fieldName: "${evid}"
        conditionOperator: RANGE
        subqueryType: Event
        ${evto ? `conditionToValue: "${evto}"` : ''}
        ${evfrom ? `conditionFromValue: "${evfrom}"` : ''}
      }` : ''

  const neverContactedCondition = lcop === 'never' ? `{
        fieldName: "last_contacted"
        conditionOperator: IS_NULL
  }` : ''

  const lastContactedCondition = lcfrom || lcto ? `{
        fieldName: "last_contacted"
        conditionOperator: RANGE
        ${lcto ? `conditionToValue: "${lcto}"` : ''}
        ${lcfrom ? `conditionFromValue: "${lcfrom}"` : ''}
      }` : ''

  const emailSubscriptionCondition = sem ? `
      {
        fieldName: "subscribed_to_email"
        conditionOperator: EQUALS
        conditionValue: "${sem}"
      }
  ` : ''

  const phoneSubscriptionCondition = sph ? `
      {
        fieldName: "sms_opted_in"
        conditionOperator: EQUALS
        conditionValue: "${sph}"
      }
  ` : ''

  const searchConditions = `searchConditions: [
      ${emailCondition}
      ${phoneCondition}
      ${fullnameCondition}
      ${lifetimeSpendCondition}
      ${lastPurchaseCondition}
      ${purchaseCountCondition}
      ${address1Contition}
      ${address2Contition}
      ${cityContition}
      ${stateContition}
      ${zipContition}
      ${categoryContition}
      ${brandContition}
      ${descriptionContition}
      ${purchaseHistoryRangeCondition}
      ${assignedAssociateCondition}
      ${customerProperties}
      ${activeListCondition}
      ${ehFromCondition}
      ${ehToCondition}
      ${ehSubjectCondition}
      ${ehBodyCondition}
      ${ehDateRangeCondition}
      ${ehOpenedCondition}
      ${eventHistoryCondition}
      ${lastContactedCondition}
      ${neverContactedCondition}
      ${emailSubscriptionCondition}
      ${phoneSubscriptionCondition}
    ]`

  return searchConditions
}

export const formatPhone = (phone) => {
  if (!phone) return ''
  if (!phone.startsWith('+1')) return phone
  
  return '' + phone.slice(2).replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
}