import React, { useContext, useMemo, useEffect, useState } from "react";
import MainLayout from "components/MainLayout";
import Table from "components/broadcasts/Table";
import useRouter from "hooks/useRouter";
import { PlusIcon } from '@heroicons/react/20/solid';
import Searchbar from "components/broadcasts/Searchbar";
import StatusFilter from "components/broadcasts/StatusFilter";
import UserContext from "contexts/UserContext";
import CustomersContext from "contexts/CustomersContext";
import usePusher from "hooks/usePusher";

const BROADCAST_STATUSES = {
  "draft": "Drafts",
  "queued": "Scheduled",
  "sending": "Sending",
  "sent": "Sent"
}

const Broadcasts = ({ eventId, status }) => {
  const { navigate } = useRouter();
  const { ecommerceExperienceFeatureFlag, excludeSmsFeatureFlag, setEventId, currentEventName } = useContext(UserContext);
  const { fetchBroadcasts } = useContext(CustomersContext);
  const [isHighlighted, setIsHighlighted] = useState(false);
  
  const [scrollPosition, setScrollPosition] = useState(0);
  const [pages, setPages] = useState([]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const searchBroadcasts = async () => {
    await fetchBroadcasts(eventId);
  };

  useEffect(() => {
    searchBroadcasts();
  }, [fetchBroadcasts]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setEventId(eventId);
    setPages([{ name: currentEventName, href: `/events/${eventId}/broadcasts`, current: true }]);
  }, [currentEventName]);

  useEffect(() => {
    if (scrollPosition > 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/broadcasts`, current: false },
        { name: BROADCAST_STATUSES[status], href: `/events/${eventId}/broadcasts/${status}`, current: true },
      ]);
    } else if (scrollPosition < 120) {
      setPages([
        { name: currentEventName, href: `/events/${eventId}/broadcasts`, current: false },
      ]);
    }
  }, [scrollPosition])

  const shouldEnablePusher = useMemo(() => {
    return !(ecommerceExperienceFeatureFlag || !eventId); 
  }, [ecommerceExperienceFeatureFlag, eventId]);
  
  usePusher(eventId, "broadcasts_channel", "status_updated", () => {
    searchBroadcasts();
    
    setIsHighlighted(true);
    setTimeout(() => setIsHighlighted(false), 1000);
  }, shouldEnablePusher);

  return (
    <MainLayout pages={pages}>
      <div className='w-full max-w-700'>
        <div className="flex flex-wrap items-center justify-between">
          <h1 id="primary-heading" className="font-bold text-3xl">
            Broadcasts
          </h1>
          {!excludeSmsFeatureFlag && (
            <button
              type="button"
              onClick={() => navigate(`/events/${eventId}/broadcasts/new`)}
              className="inline-flex items-center mb-2 mt-2 sm:m-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusIcon className="w-3 h-3 text-white mr-1" /> New text message
            </button>
          )}
        </div>
        <div>
          <StatusFilter eventId={eventId} status={status} isHighlighted={isHighlighted} />
          <Searchbar />
          <Table status={status} />
        </div>
      </div>
    </MainLayout>
  );
};

export default Broadcasts;
