export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function pluralize(singularString, count, pluralString = null) {
  if (count > 1) {
    return pluralString ? pluralString : `${singularString}s`
  } else {
    return singularString;
  }
}
