import { useCallback, useState } from "react";
import useRollbar from "./useRollbar";
import useApi from "hooks/useApi";
import { EVENTS, GET_EVENT_SETTINGS } from "utils/queries";
import { CREATE_EVENT, UPDATE_EVENT } from "utils/mutations";
import { enqueueSnackbar } from "notistack";
import moment from "moment-timezone";

const useEvents = () => {
  const { apolloClient, query } = useApi();
  const { captureError } = useRollbar();

  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");

  const [event, setEvent] = useState(null)
  const [startDateTime, setStartDateTime] = useState(null)
  const [endDateTime, setEndDateTime] = useState(null)

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { events },
      } = await query(
        EVENTS
      );
      
      setEvents(events);
      setLoading(false);
    } catch (e) {
      captureError(e);
      console.log("Failed to load events", e);
    }
  }, [captureError, query]);

  const fetchEventSettings = useCallback(async (id) => {
    setLoading(true);

    try {      
      const { data: { event } } = await query(GET_EVENT_SETTINGS(id));
      
      setStartDateTime(event.startDatetimeUtc);
      setEndDateTime(event.endDatetimeUtc);

      setEvent(event);
    } catch (e) {
      captureError(e);
      console.log("Failed to load events", e);
    }

    setLoading(false);
  }, [captureError, query]);

  const onCreate = useCallback(async () => {
    try {
      setLoading(true);

      await apolloClient.mutate({
        mutation: CREATE_EVENT,
        variables: {
          name: name
        }
      });

      enqueueSnackbar("Event has been created", {
        autoHideDuration: 3000,
        variant: "success",
      });
      setLoading(false);
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
      return false;
    }

    return true;
  }, [name, apolloClient, captureError]);

  const onUpdate = useCallback(async () => {
    setLoading(true);

    try {
      await apolloClient.mutate({
        mutation: UPDATE_EVENT,
        variables: {
          id: parseInt(event.id),
          name: event.name,
          locationName: event.locationName,
          locationUrl: event.locationUrl,
          startDatetimeUtc: moment.utc(startDateTime).toISOString(),
          endDatetimeUtc: moment.utc(endDateTime).toISOString(),
          timezone: event.timezone
        }
      });

      enqueueSnackbar("Event settings have been updated", {
        autoHideDuration: 3000,
        variant: "success"
      })
    } catch (e) {
      enqueueSnackbar(`${e.message.replace("GraphQL error:", "")}.`, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      return false;
    }

    setLoading(false);
    return true;
  }, [event, startDateTime, endDateTime, apolloClient, captureError]);

  return {
    loading,
    setLoading,
    events,
    setEvents,
    fetchEvents,
    name,
    setName,
    onCreate,
    event,
    setEvent,
    startDateTime,
    setStartDateTime,
    endDateTime,
    setEndDateTime,
    fetchEventSettings,
    onUpdate
  };
};

export default useEvents;
