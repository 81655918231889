import React, { useMemo, useCallback, useContext } from "react"
import { BROADCAST_STATUSES } from "constants/broadcastStatuses"
import { classNames } from "utils/misc"
import useRouter from "hooks/useRouter"
import CustomersContext from "contexts/CustomersContext"

const StatusFilter = ({ eventId, status, isHighlighted }) => {
  const { navigate } = useRouter()
  const {
    broadcastsDraftTotalCount,
    broadcastsScheduledTotalCount,
    broadcastsSendingTotalCount,
    broadcastsSentTotalCount,
    setBroadcastSelectedStatus,
    setBroadcastsNameContains,
    setBroadcastsSortByOrder
  } = useContext(CustomersContext)

  const onChange = useCallback(
    selectedStatus => {
      if (!selectedStatus) return

      setBroadcastsNameContains("")
      setBroadcastSelectedStatus(selectedStatus)
      setBroadcastsSortByOrder(selectedStatus === "queued" ? "asc" : "desc")

      navigate(`/events/${eventId}/broadcasts/${selectedStatus}`)
    },
    [navigate]
  )

  const getTotalCount = useCallback(
    id => {
      switch (id) {
        case "draft":
          return broadcastsDraftTotalCount
        case "queued":
          return broadcastsScheduledTotalCount
        case "sending":
          return broadcastsSendingTotalCount
        case "sent":
          return broadcastsSentTotalCount
        default:
          return 0
      }
    },
    [broadcastsDraftTotalCount, broadcastsScheduledTotalCount, broadcastsSendingTotalCount, broadcastsSentTotalCount]
  )

  return useMemo(
    () => (
      <div className="w-full mt-6">
        <div className="border-b border-gray-200 pb-0">
          <div className="mt-4">
            <nav className="-mb-px flex space-x-2 md:space-x-8">
              {BROADCAST_STATUSES.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => onChange(tab.id)}
                  className={classNames(
                    tab.id === status ? "border-indigo-500 text-indigo-600" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                  )}
                  aria-current={tab.id === status ? "page" : undefined}
                >
                  {tab.name}
                  <span
                    className={classNames(
                      tab.id === status ? "bg-indigo-100 text-indigo-600" : "bg-gray-100 text-gray-900",
                      isHighlighted ? "font-bold text-blue-700" : "font-medium",
                      "ml-1 md:ml-2 rounded-full px-2.5 py-0.5 text-xs inline-block",
                    )}
                  >
                    {getTotalCount(tab.id)}
                  </span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
    ),
    [onChange, status, broadcastsDraftTotalCount, broadcastsScheduledTotalCount, broadcastsSendingTotalCount, broadcastsSentTotalCount, isHighlighted]
  )
}

export default StatusFilter
