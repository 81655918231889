import {
  HomeIcon,
  UserGroupIcon,
  BoltIcon,
  InboxIcon,
  TableCellsIcon,
  CalendarIcon,
  ExclamationCircleIcon
} from "@heroicons/react/24/outline";

const INBOX_PATHS = [
  "/inbox",
  "/messages/inbound",
  "/messages/sent",
  "/messages/archived"
]

const BROADCAST_PATHS = [
  "/broadcasts",
  "/broadcasts/draft",
  "/broadcasts/queued",
  "/broadcasts/sending",
  "/broadcasts/sent"
]

const REPORT_PATHS = [
  "/reports/no_phone",
  "/reports/invalid",
  "/reports/landline",
  "/reports/not_yet_subscribed",
  "/reports/unsubscribed"
]

export function sidebarNavigation(eventId, 
  ecommerceExperienceFeatureFlag, 
  eventCreationDisableFeatureFlag, 
  eventSubdomainFeatureFlag, 
  currentEventName = null, 
  reportCount) {
  let menuItems = [];

  if (!eventCreationDisableFeatureFlag && !ecommerceExperienceFeatureFlag) {
    menuItems.push({ name: "Events", href: "/", icon: HomeIcon })
  }

  if (eventId && currentEventName) {
    menuItems.push({ name: currentEventName })
  }

  if (eventId) {
    menuItems.push({ name: ecommerceExperienceFeatureFlag ? "Customers" : "People", href: ecommerceExperienceFeatureFlag ? '/customers' : `/events/${eventId}/people`, icon: UserGroupIcon, paths: "/" });
  }

  if (eventId) {
    menuItems.push({ name: "Lists", href: `/events/${eventId}/lists`, icon: TableCellsIcon, paths: "/" });
  }

  if (eventId) {
    menuItems.push({ name: "Broadcasts", href: `/events/${eventId}/broadcasts/queued`, icon: BoltIcon, paths: BROADCAST_PATHS });
  }

  if (!ecommerceExperienceFeatureFlag && eventId) {
    menuItems.push({ name: "Inbox", href: `/events/${eventId}/inbox`, icon: InboxIcon, paths: INBOX_PATHS });
  }

  if (!ecommerceExperienceFeatureFlag && eventId && eventSubdomainFeatureFlag) {
    menuItems.push({ name: "Schedule", href: `/events/${eventId}/schedule`, icon: CalendarIcon, paths: "/" });
  }

  if(!ecommerceExperienceFeatureFlag && reportCount) {
    menuItems.push({ name: "Reports", href: `/events/${eventId}/reports/no_phone`, icon: ExclamationCircleIcon, paths: REPORT_PATHS });
  }

  return menuItems;
}

export const settingsTabs = [
  { id: 'account', name: 'Account', href: '/settings/account' },
  { id: 'team', name: 'Team', href: '/settings/team' },
  { id: 'advanced', name: 'Advanced', href: '/settings/advanced' },
]
