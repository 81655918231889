import gql from "graphql-tag";

export const EVENTS = gql`
  query {
    events {
      id
      name
      createdAt
    }
  }
`;

export const CUSTOMERS = (eventId, name, listId, limit, page, sortBy, sortByOrder) => gql`
  query CustomersQuery {
    customers(eventId: ${eventId}, ${name ? `name: "${name}"` : ""}, listId: ${listId ? parseInt(listId) : null}, limit: ${limit || 10}, page: ${page || 1}, sortBy: ${sortBy ? JSON.stringify(sortBy) : null}, sortByOrder: ${sortByOrder ? JSON.stringify(sortByOrder) : null}) {
      totalCount
      collection {
        id
        firstname
        lastname
        fullName
        ordersCount
        email
        initials
        formattedPhone
        phone
        lifetimeSpend
        lastContacted
        lastOrderCreatedAt
        listMemberCreatedAt
      }
    }
  }
`;

export const SELECT_ALL_CUSTOMERS = (eventId, searchConditions, total, sort) => gql`
query advancedCustomerSearch {
  advancedCustomerSearch(${searchConditions}, eventId: ${eventId}, perPageResults: ${total}, ${sort}) {
    customers {
      id
    }
  }
}
`;

export const CUSTOMER_SEARCH = (
  eventId,
  searchConditions,
  page,
  sort,
  perPageResults
) => gql`
query advancedCustomerSearch {
  advancedCustomerSearch(${searchConditions}, eventId: ${eventId}, page: ${page}, ${sort}, perPageResults: ${perPageResults}) {
    totalCount
    customers {
      id
      firstname
      lastname
      fullName
      ordersCount
      email
      initials
      formattedPhone
      phone
      lifetimeSpend
      lastContacted
      lastOrderCreatedAt
    }
  }
}
`;

export const LIST_SEARCH = (nameContains, eventId, broadcastId, limit) => gql`
query listSearch {
  listSearch(nameContains: ${
    nameContains ? JSON.stringify(nameContains) : null
  }, limit: ${limit || null}, eventId: ${parseInt(eventId)}, broadcastId: ${broadcastId || null}, sortBy: "name", sortByOrder: "ASC") {
    lists {
      id
      name,
      size: activeListMembersCount
    }
    totalCount,
  }
}
`;

export const PEOPLE_LISTS = (id) => gql`
{
  customerLists(id: ${id}) {
    listsWhereCustomerIsMember{
      id
      name
    }
    listsWhereNotCustomerIsMember {
      id
      name
    }
  }
}
`;

export const CUSTOMERS_NOT_IN_LIST = (id) => gql`
{
  listCustomers(id: ${id}) {
    customersNotInList {
      id
      fullName
      firstname
      lastname
      email
      phone
    }
  }
}
`;

export const ASSOCIATE_OPTIONS = (id) => gql`
{
  event(id: "${id}") {
    associates {
      id
      fullname
    }
  }
}
`;

export const CUSTOMER_PROPERTIES = (id) => gql`
{
  event(id: "${id}") {
    customerProperties {
      id
      name
      active
      parent {
        name
      }
    }
  }
}
`;

export const SEGMENTS = (id) => gql`
{
  event(id: "${id}") {
    segments {
      id
      name
      active
    }
  }
}
`;

export const EVENT_HISTORY = (id) => gql`
{
  event(id: "${id}") {
    shopifyEventTypes {
      active
      body
      id
      title
    }
    eventTypes {
      active
      body
      id
      title
    }
  }
}
`;

export const ME = gql`
  {
    me {
      id
      firstname
      event {
        id
        name
      }
      lastname
      fullname
      email
      mobilephone
      timeZone
      createdAt
      featuresSet
      inboundMessageOptIn
      events {
        id
        name
        phoneNumber
      }
    }
  }
`;

export const SEGMENT = (eventId, id) => gql`
{
  segment(eventId: "${eventId}", id: "${id}") {
    id
    searchConditions {
      conditionFromValue
      conditionOperator
      conditionToValue
      conditionValue
      excludeRangeLimitValues
      fieldName
      negativeConditionFlag
      subqueryType
    }
  }
}
`;

export const BROADCAST_TEMPLATES = (eventId) => gql`
  query {
    templates(eventId: ${parseInt(eventId)}, includeSharedTemplates: true) {
      id
      htmlDesign
      subject
      private
      body
      title
      createdAt
      attachments {
        id
        filename
      }
    }
  }
`;

export const BROADCAST_TEMPLATES_ID = (eventId, id) => gql`
query {
  templates(eventId: ${parseInt(eventId)}, id: ${id}) {
    id
    htmlDesign
    subject
    private
    body
    title
    createdAt
    attachments {
      id
      filename
    }
  }
}
`;

export const DEFAULT_EMAIL_TEMPLATE = gql`
  {
    me {
      event {
        defaultEmailTemplate {
          id
          htmlDesign
          subject
          private
          body
          title
          createdAt
        }
      }
    }
  }
`;

export const BROADCASTS = ({
  eventId,
  limit,
  sortBy,
  sortByOrder,
  page,
  nameContains,
  status,
}) => gql`
query broadcastSearch {
   broadcastSearch(
    eventId: ${parseInt(eventId)},
    limit: ${limit || null},
    sortBy: ${sortBy ? JSON.stringify(sortBy) : null},
    sortByOrder: ${sortByOrder ? JSON.stringify(sortByOrder) : null},
    page: ${page || null},
    nameContains: ${nameContains ? JSON.stringify(nameContains) : null},
    status: ${status ? JSON.stringify(status) : null})
   {
    broadcasts {
      id
      channel
      name
      template {
        format
      }
      list {
        id
        name
      }
      status
      sendDatetimeUtc
    }
    totalCount
    page
    draftCount
    queuedCount
    sendingCount
    sentCount
   }
}
`;
export const BROADCASTS_PER_STATUS = (params) => gql`
query broadcastSearch {
   broadcastSearch(
    eventId: ${parseInt(params.eventId)},
    countOnly: true,
    status: ${params.status ? JSON.stringify(params.status) : null})
   {
   totalCount
  }
}
`;

export const GET_BROADCAST = ({ eventId, id }) => gql`
  query GetBroadcast {
    broadcast(eventId: ${parseInt(eventId)}, id: ${id}) {
      id
      name
      sendDate: sendDatetimeUtc
      status
      channel
      senderType
      preventSendReason
      uniqueLink
      shortenedLinks
      isImmediate
      list {
        id
        name
        active
      }
      template {
        subject
        body
        attachments {
          id
          filename
          fileUrl
        }
      }
    }
  }
`;

export const GET_CUSTOMER = ({ eventId, id }) => gql`
  query GetCustomer {
    customer(eventId: ${parseInt(eventId)}, id: ${id}) {
      active
      id
      email
      firstname
      lastname
      fullName
      formattedPhone
      phone
      smsOptInDate
      smsOptOutDate
      smsOptedIn
    }
  }
`;

export const GET_CUSTOMER_MESSAGES = ({ eventId, id, limit, page, order, channel }) => gql`
  query GetCustomerMessages {
    customer(eventId: ${parseInt(eventId)}, id: ${id}) {
      messages(limit: ${limit || 10}, page: ${page || 1}, order: ${order ? JSON.stringify(order) : "asc"}, channel: ${channel ? JSON.stringify(channel) : "all"}) {
        collection {
          id
          subject
          body
          formattedBody
          fromName
          from
          to
          channel
          state
          status
          createdAt
          attachments {
            id
            filename
            fileUrl
          }
          templateAttachments {
            id
            filename
            fileUrl
          }
        }
        page
        totalCount
      }
    }
  }
`;

export const GET_MESSAGES = ({ eventId, limit, page, order, assignedToAssociateId, searchString, folderFilter }) => gql`
  query EventMessagesQuery {
    eventMessages(
      eventId: ${parseInt(eventId)}, 
      limit: ${limit || 10}, 
      page: ${page || 1}, 
      order: ${order ? JSON.stringify(order) : "desc"}, 
      assignedToAssociateId: ${assignedToAssociateId ? parseInt(assignedToAssociateId) : null}, 
      searchString: ${searchString ? JSON.stringify(searchString) : null}, 
      folderFilter: ${folderFilter ? JSON.stringify(folderFilter) : "inbox"}
    ) {
      collection {
        id
        body
        formattedBody
        fromName
        toName
        from
        to
        channel
        state
        status
        createdAt
        customer {
          id
        }
        assignedToAssociate {
          id
          firstname
          lastname
        }
        attachments {
          id
          filename
          fileUrl
        }
        templateAttachments {
          id
          filename
          fileUrl
        }
      }
      page
      totalCount
    }
  }
`;

export const GET_MESSAGE = ({ eventId, id }) => gql`
  query GetMessage {
    message(eventId: ${parseInt(eventId)}, id: ${id}) {
      id
      body
      formattedBody
      fromName
      from
      to
      channel
      state
      status
      createdAt
      customer {
        id
      }
      attachments {
        id
        filename
        fileUrl
      }
      templateAttachments {
        id
        filename
        fileUrl
      }
    }
  }
`;

export const ORG_PAYMENT_METHOD = gql`
  {
    me {
      event {
        name
        paymentMethod {
          id
          brand
          expMonth
          expYear
          last4Digits
        }
      }
    }
  }
`;

export const TIMEZONES = gql`
  {
    timezones {
      id
      name
      utcOffset
    }
  }
`;

export const GET_REMINDER = ({ eventId, customerId }) => gql`
  query GetReminder {
    reminder(eventId: ${parseInt(eventId)}, customerId: ${customerId}) {
      id
      dueAt
      note
      completed
    }
  }
`;

export const GET_EVENT_SETTINGS = (id) => gql`
  query {
    event(id: ${id}) {
      id
      name
      reportCount
      settings
      locationName
      locationUrl
      startDatetimeUtc
      endDatetimeUtc
      timezone
      subdomain
    }
  }  
`;

export const GET_TEAM_MEMBERS = () => gql`
  query { 
    teamMembers { 
      firstname 
      lastname 
      email 
      mobilephone 
    } 
  }
`;

export const GET_BROADCAST_MESSAGE_STATUSES = (id) => gql`
  query {
    broadcastMessageStatuses(id: ${id}) {
      created
      delivered
      failed
      queued
      sending
      sent
      undelivered
      totalFailedAndUndelivered
      totalQueuedAndSending
      totalSentAndDelivered
      totalCount
      noStatusCount
    }
  }  
`;

export const GET_BROADCAST_FAILED_MESSAGES = (id) => gql`
  query {
    broadcastFailedMessages(id: ${id}) {
      id
      errorCode
      status
      customer {
        id
        firstname
        lastname
        phone
      }
    }
  }  
`;

export const GET_PHONE_VERIFICATION = ({ eventId, id }) => gql`
  query {
    phoneVerification(eventId: ${eventId}, customerId: ${id}) {
      id 
      validNumber
      callingCountryCode
      countryCode
      nationalFormat 
      lineTypeIntelligence {
        type
        errorCode
        carrierName
        mobileCountryCode
        mobileNetworkCode
      }
    }
  }  
`;

export const FIND_CUSTOMER_BY_PHONE = gql`
  query FindCustomerByPhone($eventId: ID!, $phoneNumber: String!, $excludeCustomerId: ID) {
    findCustomerByPhone(eventId: $eventId, phoneNumber: $phoneNumber, excludeCustomerId: $excludeCustomerId) {
      id
      firstname
      lastname
    }
  }
`;

export const GET_CUSTOMERS_REPORT = ({ eventId, filter }) => gql`
  query {
    customerReport (eventId: ${eventId}, filter: ${JSON.stringify(filter)}) {
      invalidPhoneNumberCount
      notYetSubscribedCount
      phoneNotPresentCount
      unsubscribedCount
      nonMobilePhoneCount
      customers {
        id
        firstname
        lastname 
        phone
      }
    }
  }
`;

export const GET_ASSOCIATES = () => gql`
  query  {
    teamMembers {
      id
      firstname
      lastname
    }
  }
`;

export const GET_CUSTOMER_UPCOMING_BROADCASTS = ({ id }) => gql`
  query {
    customerUpcomingBroadcasts (id: ${id}) {
      upcomingCount
      upcomingBroadcastsForCustomer {
        id
        name
        sendDatetimeUtc
        list {
          name
        }
        template {
          body
        }
      }
    }
  }
`;

export const GET_ASSOCIATE_ACCOUNTS = () => gql`
  query {
    associateAccounts {
      id
      name
    }
  }
`;

export const GET_EVENT_SESSIONS = (eventId) => gql`
  query {
    sessions (eventId: ${eventId}) {
      id
      listId
      customerId
      name
      description
      startDatetimeUtc
      endDatetimeUtc
      locationName
      locationUrl
      list {
        id
        name
      }
      customer {
        id
        fullName
      }
    }
  }
`;

export const GET_AVAILABLE_PHONE_NUMBERS = (eventId, areaCode, containsNumbers, locality) => gql`
  query {
    availablePhoneNumbers(
      eventId: ${eventId}, 
      areaCode: ${areaCode ? JSON.stringify(areaCode) : null}, 
      containsNumbers: ${containsNumbers ? JSON.stringify(containsNumbers) : null}, 
      locality: ${locality ? JSON.stringify(locality) : null}
    )
  }  
`;