import { getTimeZoneInfo } from "utils/formatter";

const TimezoneInfo = ({ eventId, eventTimezone }) => {
  return (
    <>
      <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 ml-1.5">
        {getTimeZoneInfo(eventTimezone)}
      </span>
      <a href={`/event/edit/${eventId}`} className="text-blue-600 ml-2">change</a>
    </>  
  );
}

export default TimezoneInfo;