import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useSmsBroadcast from "hooks/useSmsBroadcast";
import { CalendarIcon, InformationCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import UserContext from "contexts/UserContext";
import MainLayout from "components/MainLayout";
import { capitalize, formatFullDateWithTimezone } from "utils/formatter";
import useRouter from "hooks/useRouter";
import ImagePreviewModal from "components/ImagePreviewModal";
import ConfirmationModal from "components/ConfirmationModal";
import { GET_BROADCAST_MESSAGE_STATUSES, GET_BROADCAST_FAILED_MESSAGES } from "utils/queries";
import useApi from 'hooks/useApi'
import useRollbar from 'hooks/useRollbar'
import TimezoneInfo from "components/TimezoneInfo";
import { classNames } from "utils/misc";
import usePusher from "hooks/usePusher";

const Broadcasts = ({ eventId, id }) => {
  const { query } = useApi();
  const { captureError } = useRollbar();

  const { loading, fetchBroadcast, broadcast, setBroadcast, onUpdateStatus, onDelete } = useSmsBroadcast();
  const { navigate } = useRouter();
  const firstAttachment = useMemo(() => broadcast?.template?.attachments[0], [broadcast]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewName, setImagePreviewName] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const { ecommerceExperienceFeatureFlag, excludeSmsFeatureFlag, setEventId, currentEventName, eventTimezone } = useContext(UserContext);
  const [deleteBroadcastConfirmationOpen, setDeleteBroadcastConfirmationOpen] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);

  const [broadcastStats, setBroadcastStats] = useState(null);
  const [broadcastFailedMsgs, setBroadcastFailedMsgs] = useState([]);

  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const openDeleteBroadcastConfirmation = useCallback(() => {
    setDeleteBroadcastConfirmationOpen(true);
  }, []);

  const onDeleteBroadcastCancel = useCallback(() => {
    setDeleteBroadcastConfirmationOpen(false);
  }, []);

  const onDeleteBroadcastConfirm = useCallback(() => {
    setDeleteBroadcastConfirmationOpen(false);
    onDelete();
  }, [onDelete]);

  const onPreviewOpen = useCallback((attachment)=> {
    setImagePreviewName(attachment.filename);
    setImagePreviewUrl(attachment.fileUrl);
    setPreviewOpen(true);
  }, []);
  const onPreviewClose = useCallback(()=> setPreviewOpen(false), []);

  const fetchBroadcastMessageStats = async (id) => {
    try {
      const queryString = GET_BROADCAST_MESSAGE_STATUSES(id);
      const res = await query(queryString);

      setBroadcastStats(res?.data?.broadcastMessageStatuses);
    } catch (e) {
      captureError(e);
    }
  }

  const fetchBroadcastFailedMessages = async (id) => {
    try {
      const queryString = GET_BROADCAST_FAILED_MESSAGES(id);
      const res = await query(queryString);

      setBroadcastFailedMsgs(res?.data?.broadcastFailedMessages);
    } catch (e) {
      captureError(e);
    }
  }

  useEffect(() => {
    fetchBroadcast(parseInt(id));
    fetchBroadcastMessageStats(id);
  }, [fetchBroadcast, id]);

  useEffect(() => {
    if (broadcast && broadcast.status === 'sent' && broadcast.channel === 'sms') {
      fetchBroadcastFailedMessages(id);
    }
  }, [broadcast])

  const pages = [
    { name: currentEventName, href: `/events/${eventId}/broadcasts`, current: false },
    { name: broadcast.name, href: `/events/${eventId}/broadcasts/${broadcast.id}`, current: true }
  ];

  const onEditBroadcast = useCallback(() => {
    navigate(`/events/${eventId}/broadcasts/edit/${broadcast.id}`)
  }, [navigate, broadcast.id, eventId]);
  
  const onClickFullName = (customerId) => {
    navigate(`/events/${eventId}/person/${customerId}`)
  };

  const formattedText = useMemo(() => {
    if (!broadcast || !broadcast.template || !broadcast.template.body) return '';

    const bodyText = broadcast.template.body;
    const urlRegex = /(?:https:\/\/|www\.)\S+(?=\s|$)/g;
    
    // Format the links
    return bodyText.replace(urlRegex, (match) => {
      return `<a href="${match}" target="_blank" class="text-blue-500 underline">${match}</a>`;
    });
  }, [broadcast]);

  const shouldEnablePusher = useMemo(() => {
    return !(ecommerceExperienceFeatureFlag || !eventId); 
  }, [ecommerceExperienceFeatureFlag, eventId]);

  usePusher(eventId, "broadcasts_channel", "status_updated", (data) => {
    if (parseInt(data.id) === broadcast.id) {
      setBroadcast((prev) => ({ ...prev, status: data.status }));

      setIsHighlighted(true);
      setTimeout(() => setIsHighlighted(false), 1000);
    }
  }, shouldEnablePusher);

  return (
    <MainLayout pages={pages}>
      <ImagePreviewModal
        name={imagePreviewName}
        url={imagePreviewUrl}
        open={previewOpen}
        onClose={onPreviewClose} />

      { deleteBroadcastConfirmationOpen &&
        <ConfirmationModal
          title="Delete this broadcast"
          description="Are you sure?"
          confirmButtonMessage="Delete"
          open={deleteBroadcastConfirmationOpen}
          onCancel={onDeleteBroadcastCancel}
          onConfirm={onDeleteBroadcastConfirm} />
      }
      <div className="px-4 sm:px-0 flex items-center">
        <h1 id="primary-heading" className="font-bold text-3xl">{broadcast.name}</h1>
        { !excludeSmsFeatureFlag && broadcast.status === "draft" && ["sms", "whatsapp"].includes(broadcast.channel) &&
          <button
            onClick={onEditBroadcast}
            type="button"
            className="ml-4 mt-[2px] rounded bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Edit broadcast
          </button>
        }
      </div>
      {!loading && (
        <>
          <div className="w-full max-w-700 mt-6 border-t border-gray-100">
            <div className="divide-y divide-gray-900/10">
              <div className="pb-4">
                <h4 className="text-base font-medium text-gray-900">Status</h4>
                <p className={classNames(isHighlighted ? "font-semibold text-blue-700" : "text-gray-700", "mt-2 text-sm")}>
                  {capitalize(broadcast.status === "queued" ? "Scheduled" : broadcast.status)}
                </p>
              </div>
              {!broadcast.isImmediate ? (
                <div className="py-4">
                  <h4 className="text-base font-medium text-gray-900">Scheduled For</h4>
                  <p className="mt-2 text-sm text-gray-700">
                    <div className="flex items-center">
                      {formatFullDateWithTimezone(broadcast.sendDate, eventTimezone)}
                      {broadcast.status === "queued" && (
                        <button
                          type="button"
                          className="ml-4 inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => onUpdateStatus('draft')}
                        >
                          <CalendarIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                          Unschedule broadcast
                        </button>
                      )}
                    </div>
                  </p>
                  <p className="mt-2">
                    All times in 
                    <TimezoneInfo eventId={eventId} eventTimezone={eventTimezone} />
                  </p>
                </div>
              ) : null}
              <div className="py-4">
                <h4 className="text-base font-medium text-gray-900">Recipient List</h4>
                <p className="mt-2 text-sm text-gray-700">
                  <a className="font-medium text-indigo-600 dark:text-indigo-500 hover:underline" href={`/events/${eventId}/people?alid=${broadcast.list?.id}`}>{broadcast.list?.name} </a> {broadcast.list?.active === false? "(Archived)":""}
                </p>
              </div>
              <div className="py-4">
                <div className="w-full max-w-450">
                  <h4 className="text-base font-medium text-gray-900">Message</h4>
                  <p className="mt-2 text-sm text-gray-700">
                    { firstAttachment &&
                      <div key={firstAttachment.id} className="mr-2 mb-2">
                        <img
                          className="max-w-[200px] max-h-[200px] hover:cursor-pointer"
                          src={firstAttachment.fileUrl}
                          alt={firstAttachment.filename}
                          onClick={() => onPreviewOpen(firstAttachment)}
                        />
                      </div>
                    }
                    { broadcast.channel === "email" &&
                      <div>
                        <strong>Subject:</strong>
                        <p
                          className="mb-2 border text-justify font-mono p-2"
                          dangerouslySetInnerHTML={{
                            __html: broadcast?.template?.subject ?? "",
                          }}
                        />
                      </div>
                    }
                    <div>
                      <p
                        className="mb-1 border text-justify font-mono p-2"
                        style={{ whiteSpace: 'pre-line' }}
                        dangerouslySetInnerHTML={{
                          __html: formattedText ?? "",
                        }}
                      />
                    </div>
                  </p>
                </div>
              </div>
              { broadcast.status === "sent" && (
                <div className="py-4">
                  <div className="w-full max-w-450">
                    <h4 className="text-base font-medium text-gray-900">Statistics</h4>
                    <div className="mt-2 text-sm text-gray-700">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="pl-6 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Status
                              </th>
                              <th scope="col" className="py-3.5 pr-7 text-right text-sm font-semibold text-gray-900">
                                Count
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            <tr key="delivered">
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">Delivered</td>
                              <td className="whitespace-nowrap py-4 pr-7 text-sm text-gray-500 text-right">{broadcastStats?.delivered}</td>
                            </tr>
                            <tr key="sent">
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500 flex items-center">
                                Sent
                                <InformationCircleIcon className="h-5 w-5 ml-2" />
                              </td>
                              <td className="whitespace-nowrap py-4 pr-7 text-sm text-gray-500 text-right">{broadcastStats?.sent}</td>
                            </tr>
                            <tr key="undelivered">
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">Undelivered</td>
                              <td className="whitespace-nowrap py-4 pr-7 text-sm text-gray-500 text-right">{broadcastStats?.totalFailedAndUndelivered}</td>
                            </tr>
                            <tr key="no_status">
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">No status</td>
                              <td className="whitespace-nowrap py-4 pr-7 text-sm text-gray-500 text-right">{broadcastStats?.noStatusCount}</td>
                            </tr>
                            <tr key="total" className="font-bold">
                              <td className="whitespace-nowrap pl-6 py-4 text-sm text-gray-500">Total</td>
                              <td className="whitespace-nowrap py-4 pr-7 text-sm text-gray-500 text-right">{broadcastStats?.totalCount}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="flex items-center mt-4 ml-1">
                        <InformationCircleIcon className="h-8 w-8 mr-2" />
                        <p className="text-sm text-gray-600">Messages marked “sent” were sent successfully, but have not received confirmation from the recipient’s carrier yet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              { broadcastFailedMsgs?.length ? (
                <div className="pt-3">
                  <h4 className="text-base font-medium text-gray-900">Delivery Errors</h4>
                  <div className="mt-2 text-sm text-gray-700">
                    <p className="mt-1 mb-4 text-sm text-gray-700">The following messages were not delivered successfully.</p>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 px-2 sm:px-4 text-left text-xs sm:text-sm font-semibold text-gray-900 sm:pl-6">
                              Name
                            </th>
                            <th scope="col" className="py-3.5 px-1 sm:px-4 text-left text-xs sm:text-sm font-semibold text-gray-900">
                              Phone
                            </th>
                            <th scope="col" className="py-3.5 px-1 sm:px-4 text-left text-xs sm:text-sm font-semibold text-gray-900">
                              Status
                            </th>
                            <th scope="col" className="py-3.5 px-1 sm:px-3 text-left text-xs sm:text-sm font-semibold text-gray-900">
                              Error Code
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {broadcastFailedMsgs.map((message) => (
                            <tr key={message.id}>
                              <td className="whitespace-nowrap py-4 px-2 sm:px-4 text-xs sm:text-sm font-medium text-gray-900 sm:pl-6 cursor-pointer" onClick={() => onClickFullName(message.customer.id)}>
                                {message.customer.firstname ?? ''} {message.customer.lastname ?? ''}
                              </td>
                              <td className="whitespace-nowrap py-4 px-1 sm:px-4 text-xs sm:text-sm text-gray-500">{message.customer.phone ?? ''}</td>
                              <td className="whitespace-nowrap py-4 px-1 sm:px-6 text-xs sm:text-sm text-gray-500">{message.status}</td>
                              <td className="whitespace-nowrap py-4 px-1 sm:px-3 text-xs sm:text-sm text-gray-500">{message.errorCode}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null }
            </div>
          </div>
          <div className="space-y-4 flex flex-col items-start justify-start sm:space-y-0 sm:flex-row sm:items-start sm:justify-start">
            { broadcast.status === "draft" &&
              <button
                type="button"
                className="hover:cursor-pointer mr-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                onClick={openDeleteBroadcastConfirmation}
              >
                <TrashIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Delete broadcast
              </button>
            }
            {/* { broadcast.status === "sending" &&
              <button
                type="button"
                className="hover:cursor-pointer mr-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onUpdateStatus('paused')}
              >
                <PauseIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Pause broadcast
              </button>
            }
            { broadcast.status === "paused" &&
              <button
                type="button"
                className="hover:cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => onUpdateStatus('queued')}
              >
                <PlayIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
                Unpause broadcast
              </button>
            } */}
          </div>
        </>
      )}
    </MainLayout>
  );
};

export default Broadcasts;
