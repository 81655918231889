import React, { useContext, useMemo, useState } from "react";
import MobileSidebar from "components/MobileSidebar";
import NewTopMenu from "components/NewTopMenu";
import NoPhoneEventAlert from "components/eventPhone/NoPhoneEventAlert";
import NarrowSidebar from "components/NarrowSidebar";
import UserContext from "contexts/UserContext";
import { useSnackbar } from "notistack";
import usePusher from "hooks/usePusher";

const MainLayout = ({ pages, children, enablePusherNotification = true }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { eventId, ecommerceExperienceFeatureFlag, hasEventPhone } = useContext(UserContext);

  const shouldEnablePusher = useMemo(() => {
    return !(ecommerceExperienceFeatureFlag || !eventId || !enablePusherNotification); 
  }, [ecommerceExperienceFeatureFlag, eventId]);

  usePusher(eventId, "messages_channel", "received_message", (data) => {
    enqueueSnackbar(data.body, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      variant: 'messageNotification',
      messageId: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      customerId: data.customer_id
    });
  }, shouldEnablePusher);

  return (
    <div className="flex">
      <NarrowSidebar />
      <MobileSidebar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <div className="flex flex-1 flex-col md:pl-[20rem]">
        { !ecommerceExperienceFeatureFlag && !hasEventPhone &&
          <NoPhoneEventAlert eventId={eventId} />
        }
        <NewTopMenu pages={pages} setMobileMenuOpen={setMobileMenuOpen} />
        <div className="flex flex-1 items-stretch">
          <main className="flex-1">
            <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last px-4 sm:px-8 pt-4 pb-8">
              { children }
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
