import { useEffect, useMemo, useCallback, useReducer, useState } from 'react'
import useDebounce from 'hooks/useDebounce'
import queryParams from 'utils/queryParams'
import { customerSearchReducer, initialState } from 'reducers/customersSearch'

const useCustomersFilter = () => {
  const [openRightSideBar, setOpenRightSideBar] = useState(true)
  const [openFilters, setOpenFilters] = useState(true)
  const [state, dispatch] = useReducer(customerSearchReducer, initialState)

  const debounceState = useDebounce(state)

  const {
    fullname: {
      id: fullname
    },
    lifetimeSpend: {
      chip: lsChip
    },
    lastPurchase: {
      chip: lpChip
    },
    purchaseCount: {
      option: pcop,
      chip: pcChip
    },
    address: {
      chips: addressChips
    },
    purchaseHistory: {
      chips: phChips
    },
    assignedAssociate: {
      chip: aaChip
    },
    customerProperties: {
      chips: cpChips
    },
    activeList: {
      chip: alChip
    },
    emailHistory: {
      chips: ehChips
    },
    eventHistory: {
      chip: evChip
    },
    lastContacted: {
      chip: lcChip
    },
    contactInformation: {
      chips: ciChips
    },
    subscriptions: {
      chips: sChips
    },
    sort: {
      id: sid
    }
  } = state

  const filterChips = useMemo(() => ({
    lifetimeSpend: lsChip,
    lastPurchase: lpChip,
    purchaseCount: pcChip,
    address: addressChips,
    purchaseHistory: phChips,
    assignedAssociate: aaChip,
    customerProperties: cpChips,
    activeList: alChip,
    emailHistory: ehChips,
    eventHistory: evChip,
    lastContacted: lcChip,
    contactInformation: ciChips,
    subscriptions: sChips
  }), [lsChip, lpChip, pcChip, addressChips, phChips, aaChip, cpChips, alChip, ehChips, evChip, lcChip, ciChips, sChips])

  const resetAll = useCallback(() => {
    if (sid) dispatch({ type: 'CLEAR', key: 'sort:id' })
    dispatch({ type: 'CLEAR', key: 'fullname' })
    Object.keys(filterChips).map(k => {
      if (typeof (filterChips[k]) === 'object') {
        return Object.keys(filterChips[k]).map(a => {
          if (!filterChips[k][a]) return null
          if (k === 'customerProperties') return dispatch({ type: 'DELETE', key: `${k}:${a}` })
          return dispatch({ type: 'CLEAR', key: `${k}:${a}` })
        })
      } else {
        return dispatch({ type: 'CLEAR', key: k })
      }
    })
  }, [filterChips, sid])

  const hasFilters = useMemo(() => {
    const test = Object.keys(filterChips).map(k => {
      if (typeof (filterChips[k]) === 'object') {
        return Object.keys(filterChips[k]).length !== 0
      } else {
        return Boolean(filterChips[k] && filterChips[k].length !== 0)
      }
    })
    if (sid || fullname) return true
    return test.includes(true)
  }, [filterChips, fullname, sid])

  useEffect(() => {
    const query = {}
    const {
      fullname: {
        id: fullname
      },
      lifetimeSpend: {
        from: lsfrom,
        to: lsto
      },
      lastPurchase: {
        option: lpop,
        from: lpfrom,
        to: lpto
      },
      purchaseCount: {
        option: pcop,
        from: pcfrom,
        to: pcto
      },
      address: {
        address1,
        address2,
        city,
        state: addressState,
        zip
      },
      purchaseHistory: {
        category,
        brand,
        description,
        from: phfrom,
        to: phto
      },
      assignedAssociate: {
        id: aaid
      },
      customerProperties: {
        options: cpOptions,
        changed: ignore,
        ...cpSelected
      },
      activeList: {
        id: alid,
        dismissed: aldismissed
      },
      emailHistory: {
        efrom: ehfrom,
        eto: ehto,
        subject: ehsubject,
        body: ehbody,
        opened: ehopened,
        from: ehdfrom,
        to: ehdto
      },
      eventHistory: {
        id: evid,
        option: evop,
        from: evfrom,
        to: evto
      },
      lastContacted: {
        option: lcop,
        from: lcfrom,
        to: lcto
      },
      contactInformation: {
        email: emid,
        phone: phid
      },
      subscriptions: {
        email: sem,
        phone: sph
      },
      sort: {
        id: sid,
        desc
      }
    } = debounceState

    if (fullname) query.fullname = fullname

    if (lsfrom) query.lsfrom = lsfrom
    if (lsto) query.lsto = lsto

    if (lpop && lpop !== 'all') query.lpop = lpop
    if (lpop === 'custom') {
      if (lpfrom) query.lpfrom = lpfrom
      if (lpto) query.lpto = lpto
    }

    if (pcop && pcop !== 'all') query.pcop = pcop
    if (pcop === 'custom') {
      if (pcfrom) query.pcfrom = pcfrom
      if (pcto) query.pcto = pcto
    }

    if (address1) query.address1 = address1
    if (address2) query.address2 = address2
    if (city) query.city = city
    if (addressState) query.adstate = addressState
    if (zip) query.zip = zip

    if (category) query.phcat = category
    if (brand) query.phbrand = brand
    if (description) query.phdesc = description
    if (phfrom) query.phfrom = phfrom
    if (phto) query.phto = phto

    if (sem) query.sem = sem
    if (sph) query.sph = sph

    if (aaid) query.aaid = aaid

    if (cpOptions && cpSelected) {
      Object.keys(cpSelected).forEach(s => {
        if (cpOptions[s]) {
          const { name } = cpOptions[s]
          if (cpSelected[s]) query[`cp${name}`] = cpSelected[s]
        }
      })
    } else {
      const params = queryParams.get()
      Object.keys(params).map(k => {
        if (k.includes('cp')) query[k] = params[k]
        return null
      })
    }

    if (alid) query.alid = alid
    if (aldismissed === 'true') query.aldismissed = aldismissed

    if (ehfrom) query.ehfrom = ehfrom
    if (ehto) query.ehto = ehto
    if (ehsubject) query.ehsubject = ehsubject
    if (ehbody) query.ehbody = ehbody
    if (ehdfrom) query.ehdfrom = ehdfrom
    if (ehdto) query.ehdto = ehdto
    if (ehopened) query.ehopened = ehopened

    if (evid) query.evid = evid
    if (evop && evid) query.evop = evop
    if (evop === 'custom') {
      if (evfrom) query.evfrom = evfrom
      if (evto) query.evto = evto
    }

    if (lcop && lcop !== 'all') query.lcop = lcop
    if (lcop === 'custom') {
      if (lcfrom) query.lcfrom = lcfrom
      if (lcto) query.lcto = lcto
    }

    if (emid) query.emid = emid
    if (phid) query.phid = phid

    if (sid) {
      query.sort = sid
      query.desc = desc
    }

    queryParams.set(query)
  }, [debounceState])

  const toggleFilterDrawer = useCallback(() => {
    setOpenRightSideBar(!openRightSideBar)
    setOpenFilters(!openFilters)
  },
  [openFilters, openRightSideBar])

  return {
    debounceState,
    state,
    dispatch,
    filterChips,
    openFilters,
    toggleFilterDrawer,
    setOpenFilters,
    openRightSideBar,
    setOpenRightSideBar,
    resetAll,
    hasFilters,
    pcop
  }
}

export default useCustomersFilter
