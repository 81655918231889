import React, { useContext } from "react";
import useRouter from "hooks/useRouter";
import logo from "assets/logo_small.svg";
import { sidebarNavigation } from "utils/navigation";
import UserContext from "contexts/UserContext";
import { classNames } from "utils/misc";

const Sidebar = () => {
  const { navigate, path } = useRouter();
  const { 
    eventId,
    ecommerceExperienceFeatureFlag, eventCreationDisableFeatureFlag, eventSubdomainFeatureFlag, 
    currentEventName,
    reportCount  } = useContext(UserContext);
    
  return (
    <nav className="min-h-screen flex z-[1202] fixed">
      <div className="hidden w-28 bg-indigo-900 overflow-y-auto md:block">
        <div className="w-full py-6 flex flex-col items-center">
          <a onClick={() => navigate("/")}>
            <div className="flex-shrink-0 flex items-center cursor-pointer">
              <img className="h-8 w-auto" src={logo} alt="Concirge" />
            </div>
          </a>
          <div className="flex-1 mt-6 w-full px-2 space-y-1">
            {sidebarNavigation(eventId, ecommerceExperienceFeatureFlag, eventCreationDisableFeatureFlag, eventSubdomainFeatureFlag, currentEventName, reportCount).map((item) => (
              <a
                key={item.name}
                onClick={() => navigate(item.href)}
                className={classNames(
                  path === item.href
                    ? "bg-indigo-800 text-white"
                    : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                  "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium cursor-pointer"
                )}
                aria-current={path === item.href ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-white"
                      : "text-indigo-300 group-hover:text-white",
                    "h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
