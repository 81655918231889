import { useEffect } from "react";
import Pusher from "pusher-js";

const usePusher = (eventId, pusherChannel, pusherEvent, onStatusUpdate, isActive = true) => {
  useEffect(() => {
    if (!isActive) return; // Stop if not active

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });
    const channel = pusher.subscribe(`${pusherChannel}_${eventId}`);

    channel.bind(pusherEvent, (data) => {
      onStatusUpdate(data); // Callback for handling updates
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [eventId, pusherChannel, pusherEvent, onStatusUpdate, isActive]);
};

export default usePusher;