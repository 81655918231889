import React, { useCallback, useContext, useEffect, useState } from "react";
import useCustomer from "hooks/useCustomer";
import { formatFullDateWithTimezone } from "utils/formatter";
import { classNames } from "utils/misc";
import moment from "moment";
import useRouter from "hooks/useRouter";

import ConfirmationModal from "components/ConfirmationModal";
import DeletePersonAlert from "./DeletePersonAlert";
import EmptyPersonState from "./EmptyPersonState";
import UserContext from "contexts/UserContext";

const PersonForm = ({ eventId, id }) => {
  const { navigate } = useRouter();
  const { eventTimezone } = useContext(UserContext);

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    smsOptIn,
    setSmsOptIn,
    onCreate,
    onEdit,
    onOptOut,
    fetchCustomer,
    findCustomerByPhone,
    customer,
    loading
  } = useCustomer();
  const [unsubscribeConfirmationOpen, setUnsubscribeConfirmationOpen] = useState(false);
  const [isSubscribedEnabled, setIsSubscribedEnabled] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false);
  const [customerByPhone, setCustomerByPhone] = useState(null);

  const smsOptInDate = moment(customer.smsOptInDate);
  const smsOptOutDate = moment(customer.smsOptOutDate);

  const openUnsubscribeConfirmation = useCallback(() => {
    setUnsubscribeConfirmationOpen(true);
  }, []);

  const onUnsubscribeCancel = useCallback(() => {
    setUnsubscribeConfirmationOpen(false);
  }, []);

  const onUnsubscribeConfirm = useCallback(() => {
    onOptOut(id);
    setUnsubscribeConfirmationOpen(false);
  }, [onOptOut, id]);

  useEffect(() => {
    const initCustomer = async () => {
      await fetchCustomer(id);
    };
    initCustomer();
  }, [id, fetchCustomer]);

  useEffect(() => {
    if (customer.phone) validatePhone(customer.phone)
  }, [customer.phone])

  const onSave = useCallback(() => {
    id ? onEdit(id) : onCreate();
  }, [id, onCreate, onEdit]);

  const validatePhone = (value) => {
    const phoneInput = value.replace(/[^\d]/g, '');
    const isValid = phoneInput.length >= 8;
    setIsSubscribedEnabled(isValid);

    if (phoneInput.length < 8 && smsOptIn) setSmsOptIn(false);

    return isValid;
  }
  
  const onPhoneChange = async (e) => {
    const phoneNumber = e.target.value;
    setPhone(phoneNumber);

    if (validatePhone(phoneNumber)) {
      if (!id) setSmsOptIn(true)

      const data = await findCustomerByPhone(eventId, phoneNumber, id);
      setCustomerByPhone(data);
    }
  }; 

  const unsubscribed = (customer.smsOptOutDate && smsOptOutDate > smsOptInDate) || (customer.smsOptOutDate && !customer.smsOptInDate);

  const navigateToProfile = () => {
    navigate(`/events/${eventId}/person/${customerByPhone.id}`);
  };

  return (
    <>
      { ((!loading && Object.getOwnPropertyNames(customer).length !== 0 && customer.active) || !id) ? (
        <form>
          <h1 id="primary-heading" className="font-bold text-3xl">{id ? "Edit" : "New"} Person</h1>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                  <input
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    type="text"
                    name="firstname"
                    id="firstname"
                    autoComplete="firstName"
                    required={true}
                    placeholder="Tina"
                    value={firstName || ""}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    autoComplete="lastName"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Fey"
                    value={lastName || ""}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                Phone number
              </label>
              <div className="mt-2">
                <div 
                  className={classNames(customerByPhone ? 'ring-red-600' : 'ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600', 'flex rounded-md shadow-sm ring-1 ring-inset')}
                >
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="phone"
                    className={classNames(customerByPhone ? 'text-red-600' : 'text-gray-900', 'block flex-1 border-0 bg-transparent py-1.5 pl-2 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-100 disabled:text-slate-500')}
                    placeholder="+15552345484"
                    // disabled={id && customer.phone}
                    value={phone || ""}
                    onChange={onPhoneChange}
                  />
                </div>
                { customerByPhone && (
                  <p className="leading-6 text-sm font-semibold text-red-600 mt-1">
                    This phone number is assigned to 
                    <span className='ml-1 underline cursor-pointer' onClick={navigateToProfile}>{customerByPhone.firstname} {customerByPhone.lastname}</span>
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="mt-5">
            { !customer.smsOptInDate && !customer.smsOptOutDate &&
              <>
                <fieldset>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="sms_opt_in"
                        name="sms_opt_in"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:bg-gray-100"
                        disabled={customer.smsOptInDate || customer.smsOptOutDate || !isSubscribedEnabled}
                        checked={smsOptIn}
                        value={smsOptIn}
                        onChange={(e) => setSmsOptIn(e.target.checked)}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="sms_opt_in" className={classNames(isSubscribedEnabled ? 'text-gray-900' : 'text-gray-400', 'font-medium')}>
                        Subscribe this phone number to broadcast messages and add this person to the All list
                      </label>
                    </div>
                  </div>    
                </fieldset>
                <p className="mt-1 text-sm leading-6 text-gray-800">
                  Ensure you have permission to contact this person in accordance with <a className="font-medium text-indigo-600 dark:text-indigo-500 hover:underline" href="https://www.conciergeteam.co/terms-conditions" target="_blank" rel="noreferrer">Concierge Terms and Conditions</a>.
                </p>
              </>
            }
          </div>
          <div>
            { ((customer.smsOptInDate && !customer.smsOptOutDate) || (customer.smsOptOutDate && customer.smsOptInDate && smsOptInDate > smsOptOutDate)) &&
              <div className="flex items-center gap-4">
                <p>Subscribed: {formatFullDateWithTimezone(customer.smsOptInDate, eventTimezone)}</p>
                <button
                  onClick={openUnsubscribeConfirmation}
                  type="button"
                  className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Unsubscribe
                </button>
              </div>
            }
            { unsubscribed && 
              <p>Unsubscribed: {formatFullDateWithTimezone(customer.smsOptOutDate, eventTimezone)}</p>
            }
          </div>

          <div className="mt-5">
            <button
              onClick={onSave}
              type="button"
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-25"
              disabled={customerByPhone}
            >
              Save
            </button>
          </div>
        </form>
      ) : !loading && (
        <EmptyPersonState eventId={eventId} /> 
      )}
      
      { customer.id && customer.active && 
        <div className="flex flex-wrap items-center gap-x-8 gap-y-4 border-t border-gray-900/10 py-6 mt-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">Archive</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">Remove {customer.firstname ?? ''} {customer.lastname ?? ''} from this event.</p>
          </div>
          <button
            type="button"
            className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
            onClick={() => setAlertOpen(true)}
          >
            Archive
          </button>
        </div>
      }

      {alertOpen && <DeletePersonAlert open={alertOpen} setOpen={setAlertOpen} id={id} />}

      { unsubscribeConfirmationOpen &&
        <ConfirmationModal
          title="Unsubscribe this person"
          description="Are you sure?"
          confirmButtonMessage="Unsubscribe"
          open={unsubscribeConfirmationOpen}
          onCancel={onUnsubscribeCancel}
          onConfirm={onUnsubscribeConfirm} />
      }
    </>
  )
};

export default PersonForm;
